import {Component, HostListener, inject} from '@angular/core';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {Subject} from 'rxjs';
import {
  ChatContainerComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-container/chat-container.component';
import {
  ChatInputComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-input/chat-input.component';
import {
  ChatLoginComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-login/chat-login.component';
import {
  FileSizeErrorComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-size-error/file-size-error.component';
import {
  FileUploadingInfoComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-uploading-info/file-uploading-info.component';
import {
  ChatHeaderComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-header/chat-header.component';
import {
  ChatFirstMessageComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-first-message/chat-first-message.component';
import {JsonPipe} from '@angular/common';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [
    ChatContainerComponent,
    ChatInputComponent,
    ChatLoginComponent,
    FileSizeErrorComponent,
    FileUploadingInfoComponent,
    ChatHeaderComponent,
    ChatFirstMessageComponent,
    JsonPipe
  ],
  templateUrl: './chat-message.component.html',
  styleUrl: './chat-message.component.scss'
})
export class ChatMessageComponent {
  chatWidgetService = inject(ChatWidgetService);

  public focus = new Subject();

  Boolean = Boolean;


  public focusMessage() {
    this.focus.next(true);
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === '/') {
      this.focusMessage();
    }
  }

}
