<form [formGroup]="chatBotService.chatBotForm">
  <div class="grid-wrapper">
    @for (question of chatBotService.questionPanel; track question) {
      <label [for]="question.type" class="radio-card">
        <input type="radio" name="request_type" [id]="question.type" formControlName="request_type"
               (change)="chatBotService.handleQuestionButton(question)"
               [value]="question.type"/>
        <div class="card-content-wrapper">
          <div class="card-content mb-0">
            <keen-icon [name]="question.icon" type="duotone"/>
            <h4 class="mb-0">{{ question.title }}</h4>
          </div>
        </div>
      </label>
    }
  </div>
</form>
