import {Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {fadeIn, fadeInOut} from '../animations';
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {CHAT_BOT, CHAT_MESSAGE} from '@chat-service/pages/customer-chat/customer-chat-config';
import {ChatMessageComponent} from '@chat-service/pages/customer-chat/chat-widget/chat-message/chat-message.component';
import {ChatBotComponent} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.component';
import {
  ChatInputComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-input/chat-input.component';
import {
  ChatLoginComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-login/chat-login.component';
import {
  ChatContainerComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-container/chat-container.component';
import {
  FileUploadingInfoComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-uploading-info/file-uploading-info.component';
import {
  FileSizeErrorComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-size-error/file-size-error.component';

@Component({
  selector: 'chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.css'],
  animations: [fadeInOut, fadeIn],
  imports: [
    NgIf,
    DatePipe,
    ChatInputComponent,
    NgForOf,
    KeenIconComponent,
    ChatLoginComponent,
    ChatContainerComponent,
    FileUploadingInfoComponent,
    FileSizeErrorComponent,
    ChatMessageComponent,
    ChatBotComponent
  ],
  standalone: true
})
export class ChatWidgetComponent implements OnInit {

  // INJECTS
  chatWidgetService = inject(ChatWidgetService);
  // Chat Box Configs ->
  @ViewChild('bottom') bottom: ElementRef;
  @Input() public theme: 'blue' | 'grey' | 'red' = 'blue';
  protected readonly fadeIn = fadeIn;
  protected readonly CHAT_BOT = CHAT_BOT;
  protected readonly CHAT_MESSAGE = CHAT_MESSAGE;

  public _visible = false;

  public get visible() {
    return this._visible;
  }

  @Input()
  public set visible(visible) {
    this._visible = visible;
    if (this._visible) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
    }
  }

  ngOnInit() {
    this.chatWidgetService.chatType = this.CHAT_BOT;

    setTimeout(() => {
      this.visible = true;
    }, 1000);
  }

  public scrollToBottom() {
    if (this.bottom !== undefined) {
      this.bottom.nativeElement.scrollIntoView();
    }
  }

  public toggleChat() {
    this.visible = !this.visible;
    this.switchToBot();
  }

  public switchToBot() {
    this.chatWidgetService.messagesData = [];
    this.chatWidgetService.leave();
    this.chatWidgetService.chatType = this.CHAT_BOT;
  }
}
