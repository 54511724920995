<div [style.opacity]="chatBotService.isChatBotAnswering || (!isMessageTypeMeterSerialNumberSelected && !chatBotService.coatoBranchCode()) ? '0.5': '1'"
     [style.pointer-events]="chatBotService.isChatBotAnswering || (!isMessageTypeMeterSerialNumberSelected && !chatBotService.coatoBranchCode()) ? 'none': 'all'"
     class="app-account-number-input-wrapper">
  <form (submit)="submit()" [formGroup]="chatBotService.chatBotForm"
        class="w-[100%] h-[100%] app-account-number-input-form">
    @if (isMessageTypeMeterSerialNumberSelected) {
      <input class="app-account-number-input-field w-[100%] h-[100%]" formControlName="meter_serial_number"
             placeholder="Hisoblagich raqami" id="meter_serial_number"
             appNumericOnly
      >
      <button
        [disabled]="!chatBotService.chatBotForm.get('meter_serial_number').value"
        type="submit" class="app-account-number-input-submit">
        <keen-icon name="send" type="duotone"
                   style="display: flex"
                   [style.opacity]="!chatBotService.chatBotForm.get('meter_serial_number').value ? '0.5': '1'"
                   class="app-account-number-input-submit-icon"/>
      </button>
    } @else {
      @if (chatBotService.coatoBranchCode()) {
        <b style="display: flex; align-items: center; color: #fff">{{ chatBotService.coatoBranchCode() }}:</b>
      }
      <input class="app-account-number-input-field w-[100%] h-[100%]" formControlName="account_number"
             placeholder="Hisob raqam" id="numericInput"
             appNumericOnly
      >
      <button
        [disabled]="!chatBotService.chatBotForm.get('account_number').value"
        type="submit" class="app-account-number-input-submit">
        <keen-icon name="send" type="duotone"
                   style="display: flex"
                   [style.opacity]="!chatBotService.chatBotForm.get('account_number').value ? '0.5': '1'"
                   class="app-account-number-input-submit-icon"/>
      </button>
    }
  </form>
</div>
