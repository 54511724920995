<app-chat-container-pre-checking>
  @if (chatWidgetService.messages.length) {
    @for (message of chatWidgetService.messages; track message.seq) {
      <div class="chat-message" id="chat-message"
           [class.chat-message-received]="!chatWidgetService.isMyMessage(message)"
           [class.chat-message-sent]="chatWidgetService.isMyMessage(message)"
      >
        <div class="chat-message-text">
          <div>
            @if (message.content?.ent) {
              <div class="d-flex align-items-center gap-4 mb-1">
                <div>
                  <keen-icon name="file-down" class="text-[26px]"/>
                </div>
                <div class="line-clamp-2">
                  {{ message.content.ent[0].data.name }}
                </div>
              </div>

              <ups-button
                [isPending]="chatWidgetService.isFileDownloading() && uniqFileRef === message.content.ent[0].data.ref"
                class="w-[100%]" label="Yuklash" styleType="secondary"
                (click)="download(message.content.ent[0].data)"></ups-button>

            } @else {
              {{ message.content }}
            }
          </div>
          <div class="text-[10px] text-right d-flex align-items-center justify-end">
            {{ message.ts.getHours() }} :  {{ message.ts.getMinutes() }}
            @if (chatWidgetService.isMyMessage(message)) {
              <keen-icon class="text-gray-700 text-[20px]" name="double-check" type="duotone"/>
            }
          </div>
        </div>
      </div>

    }
  }
</app-chat-container-pre-checking>
