import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {
  ACCOUNT_TYPE_HOUSEHOLD,
  ACCOUNT_TYPE_LEGAL,
  CHAT_BOT_PATH,
  CHAT_MESSAGE,
  COATO_BRANCHES_PATH,
  COATO_BRANCHES_REGIONS_PATH,
  MESSAGE_TYPE_BALANCE,
  MESSAGE_TYPE_CAS,
  MESSAGE_TYPE_METER_SERIAL_NUMBER,
  MESSAGE_TYPE_PROVIDE,
  MESSAGE_TYPE_TARIFF,
  REQUEST_ID_CHAT_BOT,
} from '@chat-service/pages/customer-chat/customer-chat-config';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {NegotiableCardBalanceTypes} from '../../customer-chat-enum';
import {
  IChatBotMessage,
  ICoatoBranch,
  ICoatoBranchResponse,
  ICoatoRegionResponse
} from '@chat-service/pages/customer-chat/interfaces';
import prettyNum, {PRECISION_SETTING} from 'pretty-num';
import {Subject} from 'rxjs';
import Event = ymaps.Event;

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  chatBotTyping = true;
  isChatBotAnswering = false;
  showMyResetButtonOnChatFooter = true;
  scrollSubject = new Subject<Event>();
  coatoCode: WritableSignal<string> = signal('');
  coatoBranchCode: WritableSignal<string> = signal('');
  coatoBranches: WritableSignal<ICoatoBranch[] | []> = signal([]);
  messageTypeBot = 'bot';
  messageTypeBalance = MESSAGE_TYPE_BALANCE;
  messageTypeProvide = MESSAGE_TYPE_PROVIDE;
  messageTypeCas = MESSAGE_TYPE_CAS;
  messageTypeTariff = MESSAGE_TYPE_TARIFF;
  messageTypeMeterSerialNumber = MESSAGE_TYPE_METER_SERIAL_NUMBER;
  fromBot = 'bot';
  fromMe = 'me';
  chatBotForm = new FormGroup({
    account_type: new FormControl(null),
    account_number: new FormControl(null),
    request_type: new FormControl(null),
    meter_serial_number: new FormControl(null),
  });
  // @ts-ignore
  chatBotMessages: IChatBotMessage[] = [
    {
      content: 'Assalomu Alaykum hurmatli iste\'molchi 👋, siz quyidagilar bo\'yicha tezkor ma\'lumot olishingiz mumkin:',
      type: this.messageTypeBot,
      from: this.fromBot,
    },
  ];
  questionPanel = [
    {
      title: 'Balans',
      type: this.messageTypeBalance,
      icon: 'wallet'
    },
    {
      title: 'Avariya holatlari',
      type: this.messageTypeProvide,
      icon: 'abstract-14',
    },
    {
      title: `Hisoblagich holati`,
      type: this.messageTypeCas,
      icon: 'arrow-up-refraction'
    },
    {
      title: `Ta'rifingiz`,
      type: this.messageTypeTariff,
      icon: 'colors-square'
    },
    {
      title: `Hisob raqam`,
      type: this.messageTypeMeterSerialNumber,
      icon: 'badge'
    },
  ];
  accountTypePanel = [
    {
      title: `Jismoniy`,
      type: ACCOUNT_TYPE_HOUSEHOLD,
      icon: 'user'
    }, {
      title: `Yuridik`,
      type: ACCOUNT_TYPE_LEGAL,
      icon: 'people'
    },
  ];
  protected http = inject(HttpClient);
  protected chatWidgetService = inject(ChatWidgetService);

  get requestId() {
    return localStorage.getItem(REQUEST_ID_CHAT_BOT);
  }

  set requestId(requestId) {
    localStorage.setItem(REQUEST_ID_CHAT_BOT, requestId);
  }

  handleQuestionButton(type: { title: string, type: string }) {
    this.sendMyPressedQuestionButtonMessage(type);
    this.answerForMyQuestionButtonPressedButton('Iste\'molchi turini tanlang!');
  }

  handleGetCoatoRegionId(coatoBranch: ICoatoBranch) {
    this.coatoCode.set(coatoBranch.code);
    this.chatBotMessages.push({
      content: coatoBranch.name,
      type: this.messageTypeBot,
      from: this.fromMe
    });
    // tslint:disable-next-line:max-line-length
    this.answerForMyQuestionButtonPressedButton('Hudud filiallingizni tanlang');
  }

  handleGetCoatoBranchId(coatoBranch: ICoatoBranch) {
    this.showMyResetButtonOnChatFooter = false;
    this.coatoBranchCode.set(coatoBranch.code);
    this.chatBotMessages.push({
      content: coatoBranch.name,
      type: this.messageTypeBot,
      from: this.fromMe
    });
    // tslint:disable-next-line:max-line-length
    this.answerForMyQuestionButtonPressedButton('Hisob raqamingizni kiriting');
  }

  handleAccountTypeButton(type: { title: string, type: string }) {

    this.sendMyPressedQuestionButtonMessage(type);

    // tslint:disable-next-line:max-line-length
    if (this.checkIfMessageTypeMeterSerialNumberSelected()) {
      this.showMyResetButtonOnChatFooter = false;
      this.answerForMyQuestionButtonPressedButton('Hisoblagich raqamingizni kiriting');
    } else {
      this.answerForMyQuestionButtonPressedButton('Hududingizni tanlang');
    }

  }

  answerForMyQuestionButtonPressedButton(title: string) {
    this.isChatBotAnswering = true;

    setTimeout(() => {
      this.chatBotMessages.push({
        content: title,
        type: this.messageTypeBot,
        from: this.fromBot
      });
      this.isChatBotAnswering = false;
    }, 2000);
    this.scrollToBottom();
  }

  switchToOperator() {
    this.chatWidgetService.chatType = CHAT_MESSAGE;
  }

  sendMyPressedQuestionButtonMessage(type: { title: string, type: string }) {
    this.chatBotMessages.push({
      content: type.title,
      type: type.type,
      from: this.fromMe
    });
    this.scrollToBottom();
  }

  resetMySelectedValuesFromChatBotForm() {
    this.chatBotForm.reset();
    this.showMyResetButtonOnChatFooter = true;
    this.coatoCode.set('');
    this.coatoBranchCode.set('');
    this.coatoBranches.set([]);
  }


  handleFormSubmit() {

    // tslint:disable-next-line:max-line-length
    const accountOrMeterSerialNumber = this.checkIfMessageTypeMeterSerialNumberSelected() ? this.chatBotForm.get('meter_serial_number').value : this.chatBotForm.get('account_number').value;

    // tslint:disable-next-line:max-line-length
    const hasCoatoBranchCode = this.coatoBranchCode() ? this.coatoBranchCode() + this.chatBotForm.get('account_number').value : this.chatBotForm.get('account_number').value;

    this.chatBotMessages.push({
      content: accountOrMeterSerialNumber,
      type: this.messageTypeBot,
      from: this.fromMe
    });

    this.isChatBotAnswering = true;

    this.http.request('POST', CHAT_BOT_PATH, {
      params: {
        ...this.removeEmptyProperties({...this.chatBotForm.value, account_number: hasCoatoBranchCode}),
        request_id: this.requestId ?? ''
      }
    }).subscribe((res: any) => {
        if (res.success && !res.data.request_id) {
          this.chatBotMessages.push({
            content: `Ma'lumot topilmadi`,
            type: this.messageTypeBot,
            from: this.fromBot
          });
        }

        if (res.success) {
          if (res.data.request_type === MESSAGE_TYPE_BALANCE) {
            this.requestTypeBalanceMapData(res.data);
          } else if (res.data.request_type === MESSAGE_TYPE_PROVIDE) {
            this.requestTypeProvideMapData(res.data);
          } else if (res.data.request_type === MESSAGE_TYPE_CAS) {
            this.requestTypeCasMapData(res.data);
          } else if (res.data.request_type === MESSAGE_TYPE_TARIFF) {
            this.requestTypeTariffMapData(res.data);
          } else if (res.data.request_type === MESSAGE_TYPE_METER_SERIAL_NUMBER) {
            this.requestTypeMeterSerialNumberMapData(res.data);
          }
        }

        if (res.data.request_id) {
          this.requestId = res.data.request_id;
        }

        this.isChatBotAnswering = false;
        this.chatBotForm.get('account_number').reset();
        this.chatBotForm.get('meter_serial_number').reset();
        this.scrollToBottom();

      },
      error => {
        this.isChatBotAnswering = false;
        this.chatBotForm.get('account_number').reset();
        this.chatBotForm.get('meter_serial_number').reset();
      }
    );

  }

  requestTypeBalanceMapData(data: any): void {
    let sumBalance = 0;
    let balances = [];


    if (this.chatBotForm.get('account_type').value === ACCOUNT_TYPE_HOUSEHOLD) {
      balances = this.divideToObject(data.balances);
      sumBalance = this.calculateSum(balances);
    }

    if (this.chatBotForm.get('account_type').value === ACCOUNT_TYPE_LEGAL) {
      balances = data.balance.balances
        .sort((a, b) => a.accountTypeCode - b.accountTypeCode)
        .map((balance: any) => balance.balanceOut * -1);

      sumBalance = balances.reduce((acc, curr) => acc + curr, 0);
    }


    this.chatBotMessages.push({
      content: `Balans holati: ${sumBalance >= 0 ? 'yaxshi' : 'Qarz bor'}: ${this.formatNumber(sumBalance, 2)}`,
      type: this.messageTypeBalance,
      from: this.fromBot,
    });
  }

  divideToObject(arr: any[] = []) {
    let electricity, act, fine, actUnaccountedConsumption, balance;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]['accountTypeCode'] === NegotiableCardBalanceTypes.ELECTRICITY) {
        electricity = arr[i];
      } else if (arr[i]['accountTypeCode'] === NegotiableCardBalanceTypes.ACT) {
        act = arr[i];
      } else if (arr[i]['accountTypeCode'] === NegotiableCardBalanceTypes.FINE) {
        fine = arr[i];
      } else if (arr[i]['accountTypeCode'] === NegotiableCardBalanceTypes.ACT_UNACCOUNTED_CONSUMPTION) {
        actUnaccountedConsumption = arr[i];
      } else if (arr[i]['accountTypeCode'] === NegotiableCardBalanceTypes.SERVICES) {
        balance = arr[i];
      }
    }

    electricity.balanceOut *= -1;
    act.balanceOut *= -1;
    actUnaccountedConsumption.balanceOut *= -1;
    fine.balanceOut *= -1;
    balance.balanceOut *= -1;

    return [electricity, act, actUnaccountedConsumption, fine, balance];
  }

  calculateSum(arr: any[]) {
    let allBalanceOut = 0;

    for (let i = 0; i < arr.length; i++) {
      allBalanceOut += arr[i]['balanceOut'];
    }
    return allBalanceOut;
  }

  formatNumber(number, fractions) {
    return prettyNum((number / 10 ** fractions).toFixed(fractions), {
      precision: fractions,
      precisionSetting: PRECISION_SETTING.FIXED,
      thousandsSeparator: ' ',
    });
  }

  requestTypeProvideMapData(data: any) {
    if (!(data.title_ru || data.title_uz) && !(data.message_ru || data.message_uz)) {
      this.chatBotMessages.push({
        content: 'Elektr uzilishlari aniqlanmadi',
        type: this.messageTypeProvide,
        from: this.fromBot,
      });
    } else {
      this.chatBotMessages.push({
        content: `${data.title_ru} : ${data.message_ru}`,
        type: this.messageTypeProvide,
        from: this.fromBot,
      });
    }
  }

  requestTypeCasMapData(data: any) {
    this.chatBotMessages.push({
      content: `Hisoblagich holati: ${data.status ? 'Yoniq' : 'O\'chik'}`,
      type: this.messageTypeCas,
      from: this.fromBot,
    });
  }

  requestTypeTariffMapData(data: any) {
    this.chatBotMessages.push({
      content: `Tariff: ${data.nameUz}`,
      type: this.messageTypeTariff,
      from: this.fromBot,
    });
  }

  requestTypeMeterSerialNumberMapData(data: any) {
    this.chatBotMessages.push({
      content: `F.I.O: ${data.full_name}
            Hisob raqamingiz: ${data.account_number}`,
      type: this.messageTypeMeterSerialNumber,
      from: this.fromBot,
    });
  }

  checkIfMessageTypeMeterSerialNumberSelected() {
    return this.chatBotForm.get('request_type').value === this.messageTypeMeterSerialNumber;
  }

  getCoatoBranchesRegion() {
    this.http.get<ICoatoRegionResponse>(COATO_BRANCHES_REGIONS_PATH, {
      params: {
        parent_code: '00000',
      }
    }).subscribe(res => {
      if (res.success) {
        this.coatoBranches.set(res.data.data);
      } else {
        this.coatoBranches.set([]);
      }
      this.scrollToBottom();
    });
  }

  getCoatoBranches() {
    this.coatoBranches.set([]);
    this.http.get<ICoatoBranchResponse>(COATO_BRANCHES_PATH, {params: {parent_code: this.coatoCode()}}).subscribe(res => {
      if (res.success) {
        this.coatoBranches.set(res.data.data);
      } else {
        this.coatoBranches.set([]);
      }
      this.scrollToBottom();
    });
  }


  // UTILS
  /* eslint-disable */
  removeEmptyProperties(obj: any) {
    const result: any = {};
    Object.keys(obj).forEach((key: string) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const res = this.removeEmptyProperties(obj[key]);
        if (Object.keys(res).length > 0) {
          result[key] = res;
        }
      } else if (obj[key] !== null && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    });

    return result;
  }

  scrollToBottom() {
    setTimeout(() => {
      this.scrollSubject.next(null);
    }, 0);
  }

}


