<div class="chat-box">
  <app-chat-header/>
  <div class="chat-box-main p-2 relative">
    <div #bottom class="chat-message-bottom"></div>

    @if (this.chatWidgetService.secretKey && this.chatWidgetService.topic) {
      <app-chat-container/>
    } @else {
      <app-chat-first-message/>
    }
  </div>
  @if (chatWidgetService.secretKey && chatWidgetService.topic) {
    <!--Chat box footer-->
    @if (chatWidgetService.isFileSizeError()) {
      <app-file-size-error/>
    }

    @if (chatWidgetService.isFileUploading()) {
      <app-file-uploading-info/>
    }
    <div class="chat-box-footer">
      <chat-input [focus]="focus"></chat-input>
    </div>
  } @else {
    @if (Boolean(chatWidgetService.firstMessage.length === 0)) {
      <div class="chat-box-footer">
        <chat-input [focus]="focus"></chat-input>
      </div>
    }
  }
</div>
