import {Component} from '@angular/core';
import {
  ResetMyValuesComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/reset-my-values/reset-my-values.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';

@Component({
  selector: 'app-reset-my-values-chat-box',
  standalone: true,
  imports: [
    KeenIconComponent
  ],
  templateUrl: './reset-my-values-chat-box.component.html',
  styleUrl: './reset-my-values-chat-box.component.scss'
})
export class ResetMyValuesChatBoxComponent extends ResetMyValuesComponent {

}
