import {Component, inject, OnInit} from '@angular/core';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';

@Component({
  selector: 'app-coato-branches-allowed-coato-list',
  standalone: true,
  imports: [
    KeenIconComponent
  ],
  templateUrl: './coato-branches-allowed-coato-list.component.html',
  styleUrl: './coato-branches-allowed-coato-list.component.scss'
})
export class CoatoBranchesAllowedCoatoListComponent implements OnInit {
  chatBotService = inject(ChatBotService);

  ngOnInit() {
    this.chatBotService.getCoatoBranches();
  }

}
