import {Component, inject, OnInit, signal} from '@angular/core';
import {
  ChatLoginComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-login/chat-login.component';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {JsonPipe} from '@angular/common';
import {
  TypingIndicatorDotsComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/typing-indicator-dots/typing-indicator-dots.component';

@Component({
  selector: 'app-chat-first-message',
  standalone: true,
  imports: [
    ChatLoginComponent,
    ButtonComponent,
    KeenIconComponent,
    JsonPipe,
    TypingIndicatorDotsComponent
  ],
  templateUrl: './chat-first-message.component.html',
  styleUrl: './chat-first-message.component.scss'
})
export class ChatFirstMessageComponent implements OnInit {
  // INJECTS
  chatWidgetService = inject(ChatWidgetService);
  // STATE
  isOperatorBotTyping = signal(true);
  protected readonly Boolean = Boolean;

  ngOnInit() {
    this.sendTextFromOperatorBotName();
  }


  sendTextFromOperatorBotName() {

    if (Boolean(this.chatWidgetService.firstMessage.length === 0)) {
      setTimeout(() => {
        this.isOperatorBotTyping.set(false);
      }, 1000);
      setTimeout(() => {
        this.chatWidgetService.operatorBotMessage = 'Assalomu Alaykum';
      }, 1200);
    }
  }
}
