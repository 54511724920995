@if (Boolean(chatWidgetService.firstMessage.length > 0)) {
  <div class="mb-2">
    @for (message of chatWidgetService.operatorBotMessage; track message.content) {
      <div class="chat-message" id="chat-operatorBotMessage-get"
           [class.chat-message-received]="true"
      >
        <div class="chat-message-text">
          <div class="text-[10px] text-right d-flex align-items-center">
            {{ message.content }}
          </div>
        </div>
      </div>
    }
    @for (message of chatWidgetService.firstMessage; track message.message) {
      <div class="chat-message" id="chat-message"
           [class.chat-message-sent]="true"
      >
        <div class="chat-message-text">
          <div class="text-[10px] text-right d-flex align-items-center justify-end">
            {{ message.message }}
          </div>
        </div>
      </div>
    }
  </div>
  <div class="card">
    <app-chat-login/>
  </div>
} @else {

  @if (isOperatorBotTyping()) {
    <div class="chat-message chat-message-from-bot" style="width: 150px">
      <div class="chat-message-text m-0">
        <app-typing-indicator-dots/>
      </div>
    </div>
  } @else {
    @for (message of chatWidgetService.operatorBotMessage; track message.content) {
      <div class="chat-message" id="chat-operatorBotMessage"
           [class.chat-message-received]="true"
      >
        <div class="chat-message-text">
          <div class="text-[10px] text-right d-flex align-items-center">
            {{ message.content }}
          </div>
        </div>
      </div>
    }
  }
}
