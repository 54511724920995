<div class="chat-box-header">
  @if (chatWidgetService.initTinodeChatServiceService.tinode) {
    @if (chatWidgetService.topic && chatWidgetService.secretKey) {
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; align-items: center">
          <keen-icon name="user-square" size="large-1" type="duotone"></keen-icon>
          <span>{{ chatWidgetService.initTinodeChatServiceService.tinode.operatorName }}</span>
        </div>
        <div>
          <button (click)="logOut()"
                  style="display: flex; justify-content: center; align-items: center">
            <keen-icon name="left-square" size="large-1" type="duotone"></keen-icon>
          </button>
        </div>

      </div>

    }
  } @else {
    <div style="display: flex; align-items: center">
      <keen-icon name="user-square" size="large-1" type="duotone"></keen-icon>
      <span>Operator</span>
    </div>

  }
</div>
