import {AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {
  TypingIndicatorDotsComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/typing-indicator-dots/typing-indicator-dots.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {
  AccountTypeSelectComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/account-type-select/account-type-select.component';
import {
  ChatContainerComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-container/chat-container.component';
import {
  ChatInputComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-input/chat-input.component';
import {
  ChatLoginComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-login/chat-login.component';
import {
  FileSizeErrorComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-size-error/file-size-error.component';
import {
  FileUploadingInfoComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/file-uploading-info/file-uploading-info.component';
import {
  QuestionButtonsComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/question-buttons/question-buttons.component';
import {
  AccountNumberInputComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/account-number-input/account-number-input.component';
import {
  ResetMyValuesComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/reset-my-values/reset-my-values.component';
import {
  ResetMyValuesChatBoxComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/reset-my-values-chat-box/reset-my-values-chat-box.component';
import {
  CoatoBranchesRegionsComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/coato-branches/coato-branches-regions/coato-branches-regions.component';
import {
  CoatoBranchesAllowedCoatoListComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/components/coato-branches/coato-branches-allowed-coato-list/coato-branches-allowed-coato-list.component';
import {CoreCommonModule} from '@core/common.module';

@Component({
  selector: 'app-chat-bot',
  standalone: true,
  imports: [
    ChatContainerComponent,
    ChatInputComponent,
    ChatLoginComponent,
    FileSizeErrorComponent,
    FileUploadingInfoComponent,
    TypingIndicatorDotsComponent,
    ButtonComponent,
    KeenIconComponent,
    AccountTypeSelectComponent,
    QuestionButtonsComponent,
    AccountNumberInputComponent,
    ResetMyValuesComponent,
    ResetMyValuesChatBoxComponent,
    CoatoBranchesRegionsComponent,
    CoatoBranchesAllowedCoatoListComponent,
    CoreCommonModule,
  ],
  templateUrl: './chat-bot.component.html',
  styleUrl: './chat-bot.component.scss'
})
export class ChatBotComponent implements OnInit, AfterViewInit {
  chatBotService = inject(ChatBotService);
  contentHeight: any;
  @ViewChild('contentRef') contentRef: ElementRef;
  protected readonly Boolean = Boolean;

  ngAfterViewInit() {
    this.contentRef.nativeElement.scrollTop = this.contentRef.nativeElement.scrollHeight;
  }


  ngOnInit() {
    this.chatBotService.scrollSubject.subscribe(() => {
      this.contentRef.nativeElement.scrollTop = this.contentRef.nativeElement.scrollHeight;
      this.contentRef.nativeElement.scrollIntoView({behavior: 'smooth'});
    });

    setTimeout(() => {
      this.chatBotService.chatBotTyping = false;
    }, 2000);
  }
}
