import {NetworkProviders, Utilities} from './utilities';
import {AuthToken} from './models';
import {Tinode} from './tinode';
import {Subject} from 'rxjs';
import {IFileDownload} from '../../../interfaces';
import {ELocalStorage} from '../../../../../../../../core/enums/LocalStorage.enum';
import {FILE_SERVICE_PATH} from '../../../customer-chat-config';

export class LargeFileHelper {
  onProgress = new Subject<any>();
  onSuccess = new Subject<any>();
  onFailure = new Subject<any>();
  private authToken: AuthToken;
  private tinode: Tinode;
  private apiKey = '';
  private msgId = '';
  private xhr = NetworkProviders.XMLHTTPRequest;
  private toResolve: any;
  private toReject: any;

  constructor(tinode: Tinode) {
    this.tinode = tinode;
    this.authToken = this.tinode.authToken;
    this.apiKey = this.tinode.connectionConfig.APIKey;
    this.msgId = tinode.getNextUniqueId();
  }

  getBearerToken() {
    const bearerTokenKey = ELocalStorage.ACCESS_TOKEN;
    return sessionStorage.getItem(bearerTokenKey);
  }

  upload(file: File) {
    const baseUrl = FILE_SERVICE_PATH + 'upload';
    return this.uploadWithBaseUrl(baseUrl, file);
  }

  getFileName(ref: string) {
    return ref.split('/').pop();
  }

  downloadFile(file: IFileDownload) {
    const filePath = FILE_SERVICE_PATH + 'download?filename=' + this.getFileName(file.ref);
    return this.download(filePath, file.name, file.mime);
  }

  download(relativeUrl: string, filename: string, mimetype: string) {
    if (!this.authToken) {
      throw new Error('Must authenticate first');
    }
    const instance = this;
    const xhr: XMLHttpRequest = new XMLHttpRequest(); // Instantiate XMLHttpRequest object

    xhr.open('GET', relativeUrl, true);
    xhr.setRequestHeader('X-Tinode-APIKey', this.apiKey);
    xhr.setRequestHeader('Authorization', `Bearer ${this.getBearerToken()}`);
    xhr.setRequestHeader('X-Tinode-Auth', 'Token ' + this.authToken.token);

    xhr.onprogress = (e) => {
      if (instance.onProgress) {
        // Passing e.loaded instead of e.loaded/e.total because e.total
        // is always 0 with gzip compression enabled by the server.
        instance.onProgress.next(e.loaded);
      }
    };

    const result = new Promise((resolve, reject) => {
      this.toResolve = resolve;
      this.toReject = reject;
    });

    // The blob needs to be saved as file. There is no known way to
    // save the blob as file other than to fake a click on an <a href... download=...>.
    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.response);

        fetch(response.data.ctrl.text)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            // URL.createObjectURL is not available in non-browser environment. This call will fail.
            link.href = URL.createObjectURL(blob);

            link.style.display = 'none';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
            if (instance.toResolve) {
              instance.toResolve();
            }
          });
      }
    };

    xhr.onerror = (e) => {
      console.log(e);
      if (instance.toReject) {
        instance.toReject(new Error('failed'));
      }
    };

    xhr.onabort = () => {
      if (instance.toReject) {
        instance.toReject(null);
      }
    };

    try {
      xhr.send();
    } catch (err) {
      if (this.toReject) {
        this.toReject(err);
      }
    }

    return result;
  }

  cancel() {
    if (this.xhr && this.xhr.readyState < 4) {
      this.xhr.abort();
    }
  }

  getId() {
    return this.msgId;
  }

  /**
   * Start uploading the file to a non-default endpoint.
   * @param baseUrl alternative base URL of upload server.
   * @param file to upload
   */
  private uploadWithBaseUrl(baseUrl: string, file: File) {
    if (!this.authToken) {
      throw new Error('Must authenticate first');
    }

    const instance = this;

    const xhr: XMLHttpRequest = new XMLHttpRequest(); // Instantiate XMLHttpRequest object

    xhr.open('POST', baseUrl, true);
    xhr.setRequestHeader('X-Tinode-APIKey', instance.apiKey);
    xhr.setRequestHeader('Authorization', `Bearer ${this.getBearerToken()}`);
    xhr.setRequestHeader('X-Tinode-Auth', 'Token ' + instance.authToken.token);

    const result = new Promise<any>((resolve, reject) => {
      this.toResolve = resolve;
      this.toReject = reject;
    });

    xhr.upload.onprogress = (e: any) => {
      if (e.lengthComputable) {
        instance.onProgress.next(Math.round((100 * e.loaded) / e.total));
      }
    };

    xhr.onload = () => {
      const temp: any = this;
      let pkt: any;
      try {
        pkt = JSON.parse(xhr.response, Utilities.jsonParseHelper);

      } catch (err) {
        this.tinode.logger('ERROR: Invalid server response in LargeFileHelper', temp.response);
        pkt = {
          ctrl: {
            code: xhr.status,
            text: xhr.statusText
          }
        };
      }
      if (xhr.status >= 200 && xhr.status < 300) {

        if (instance.toResolve) {
          instance.toResolve(pkt.data.ctrl.params.url);
        }
        instance.onSuccess.next(pkt.data.ctrl);
      } else if (xhr.status >= 400) {
        if (instance.toReject) {
          instance.toReject(new Error(pkt.ctrl.text + ' (' + pkt.ctrl.code + ')'));
        }
        instance.onFailure.next(pkt.data.ctrl);
      } else {
        instance.tinode.logger('ERROR: Unexpected server response status', xhr.status, xhr.response);
      }
    };

    xhr.onerror = (e: any) => {
      if (instance.toReject) {
        instance.toReject(new Error('failed'));
      }
      instance.onFailure.next(null);
    };

    xhr.onabort = (e: any) => {
      if (instance.toReject) {
        instance.toReject(new Error('failed'));
      }
      instance.onFailure.next(null);
    };

    try {
      const form = new FormData();
      form.append('file', file);
      form.set('id', this.msgId);
      xhr.send(form);
    } catch (err) {
      if (instance.toReject) {
        instance.toReject(new Error(err));
      }
      this.onFailure.next(null);
    }
    return result;
  }
}
