import {Component, inject} from '@angular/core';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';

@Component({
  selector: 'app-reset-my-values',
  standalone: true,
  imports: [
    KeenIconComponent
  ],
  templateUrl: './reset-my-values.component.html',
  styleUrl: './reset-my-values.component.scss'
})
export class ResetMyValuesComponent {
  chatBotService = inject(ChatBotService);
}
