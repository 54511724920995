import {inject, Injectable, signal} from '@angular/core';
import {DefaultService} from '@core/services/default.service';
import {LocalStorageService} from '@core/services/storages/local-storage.service';
import {
  CHAT_TYPE,
  CUSTOMER_CONNECTION_TOPIC_KEY,
  CUSTOMER_SECRET_KEY,
  IS_FIRST_MESSAGE,
  OPERATOR_BOT_MESSAGE,
  REQUEST_ID_CHAT_BOT,
  TINODE_PARAMS_DATA,
  TINODE_PARAMS_DEL,
  TINODE_PARAMS_DESC,
  TINODE_PARAMS_SUB
} from '@chat-service/pages/customer-chat/customer-chat-config';
import {AuthenticationScheme} from '../tinode-sdk/typescript-sdk-master/src/constants';
import {IFileDownload, ITinodeMessage} from '@chat-service/pages/customer-chat/interfaces';
import {LargeFileHelper, Message} from '../tinode-sdk/typescript-sdk-master/src';
import {InitTinodeChatServiceService} from '@chat-service/pages/customer-chat/services/init-tinode-chat-service.service';

@Injectable({
  providedIn: 'root'
})
export class ChatWidgetService extends DefaultService {
  messagesData: ITinodeMessage[] = [];
  isLoading = signal(false);
  isFileUploading = signal(false);
  isFileDownloading = signal(false);
  isFileSizeError = signal(false);

  localStorageService = inject(LocalStorageService);
  initTinodeChatServiceService = inject(InitTinodeChatServiceService);


  formName = '#chatwidget';

  get messages() {
    // @ts-ignore
    return this.messagesData.sort((a: Message, b: Message) => a.seq - b.seq);
  }

  get secretKey() {
    return this.localStorageService.get(CUSTOMER_SECRET_KEY);
  }

  set secretKey(secret: string) {
    this.localStorageService.set(CUSTOMER_SECRET_KEY, secret);
  }

  get topic() {
    return this.localStorageService.get(CUSTOMER_CONNECTION_TOPIC_KEY);
  }

  set topic(topic: string) {
    this.localStorageService.set(CUSTOMER_CONNECTION_TOPIC_KEY, topic);
  }

  get chatType() {
    return this.localStorageService.get(CHAT_TYPE);
  }

  // @ts-ignore
  set chatType(type: CHAT_BOT | CHAT_MESSAGE) {
    this.localStorageService.set(CHAT_TYPE, type);
  }

  get firstMessage(): any {
    const data = this.localStorageService.get(IS_FIRST_MESSAGE);

    try {
      return JSON.parse(data) ?? [];
    } catch (e) {
      return this.localStorageService.get(IS_FIRST_MESSAGE);
    }
  }

  // @ts-ignore
  set firstMessage(message: string) {
    this.localStorageService.set(IS_FIRST_MESSAGE, JSON.stringify([{message}]));
  }

  get operatorBotMessage(): any {
    const data = this.localStorageService.get(OPERATOR_BOT_MESSAGE);

    try {
      return JSON.parse(data) ?? [];
    } catch (e) {
      return this.localStorageService.get(OPERATOR_BOT_MESSAGE);
    }
  }

  set operatorBotMessage(content: string) {
    this.localStorageService.set(OPERATOR_BOT_MESSAGE, JSON.stringify([{content}]));
  }

  getUrl(): string {
    return 'chat-api/api/v1/user/socket';
  }

  resetMessagesData() {
    this.messagesData = [];
  }

  async initializeChatConnection() {
    return this.initTinodeChatServiceService.initTinodeChat();
  }

  resetSecretAndTopic() {
    this.logOutFromChat();
  }

  startChat() {
    this.initializeChatConnection().then(() => {
      this.isLoading.set(true);
      setTimeout(() => {
        this.tinodeLogin();
      }, 2000);
    });
  }

  tinodeLogin() {
    if (!this.initTinodeChatServiceService.tinode) {
      return;
    }
    this.initTinodeChatServiceService.tinode.login(AuthenticationScheme.Basic, this.secretKey).then(() => {
      this.getMessages();
      if (this.firstMessage[0].message) {
        this.sendMessage(this.firstMessage[0].message);
      }
    }).catch(() => {
      this.isLoading.set(false);
    });
  }


  sendMessage(msg: string) {
    this.initTinodeChatServiceService.tinode.publish(this.topic, msg);
  }

  getMessages() {
    this.initTinodeChatServiceService.tinode.subscribe(this.topic, {
      'what': `${TINODE_PARAMS_DATA} ${TINODE_PARAMS_SUB} ${TINODE_PARAMS_DESC} ${TINODE_PARAMS_DEL} `
    }, {}).then(() => {
      this.initTinodeChatServiceService.tinode.onDataMessage.subscribe(val => {
        const value = val as ITinodeMessage;

        if (this.messagesData.every(item => this.isMyMessage(item))) {
          localStorage.removeItem('IS_FIRST_MESSAGE');
        }

        this.messagesData.push({...value, isDownloading: false});
      });
      this.isLoading.set(false);
    });
  }

  sendTypingInfo() {
    const topicName = this.topic;

    this.initTinodeChatServiceService.tinode.noteKeyPress(topicName);
    this.sendNote();
  }


  sendNote() {
    const lastMessage = this.messages[this.messages.length - 1] as ITinodeMessage;
    if (!lastMessage) {
      return;
    }

    this.initTinodeChatServiceService.tinode.note(this.topic, 'read', lastMessage.seq);
  }

  isMyMessage(message: ITinodeMessage) {
    return this.initTinodeChatServiceService.tinode.isMe(message.from);
  }

  fileUpload(file: File) {
    this.isFileUploading.set(true);

    const largeFileHelper = new LargeFileHelper(this.initTinodeChatServiceService.tinode);

    largeFileHelper.upload(file).then(res => {
      const msg = this.initTinodeChatServiceService.tinode.attachFile(null, {
        mime: file.type,
        filename: file.name,
        size: file.size,
        url: res
      });

      this.initTinodeChatServiceService.tinode.sendFile(this.topic, msg);
      this.isFileUploading.set(false);
    }).catch(error => {
      this.isFileUploading.set(false);
      console.log(error);
    });
  }

  fileDownload(file: IFileDownload) {
    this.isFileDownloading.set(true);

    const largeFileHelper = new LargeFileHelper(this.initTinodeChatServiceService.tinode);

    largeFileHelper.downloadFile(file).then(r => {
      console.log(r);
      this.isFileDownloading.set(false);
    }).catch(error => {
      console.log(error);
      this.isFileDownloading.set(false);
    });
  }

  logOutFromChat() {
    this.leave();
    localStorage.removeItem(CUSTOMER_CONNECTION_TOPIC_KEY);
    localStorage.removeItem(CUSTOMER_SECRET_KEY);
    localStorage.removeItem(IS_FIRST_MESSAGE);
    localStorage.removeItem(OPERATOR_BOT_MESSAGE);
    localStorage.removeItem(REQUEST_ID_CHAT_BOT);
    if (this.initTinodeChatServiceService.tinode) {
      this.initTinodeChatServiceService.tinode.isFailedConnection.set(false);
      this.isLoading.set(false);
      this.initTinodeChatServiceService.tinode = null;
      this.messagesData = [];
    }
  }

  leave() {
    if (this.topic && this.secretKey) {
      this.initTinodeChatServiceService.tinode.leave(this.topic, false);
    }
  }

  sendFirstMessageWhen() {
    return this.messagesData.every(item => this.isMyMessage(item));
  }
}
