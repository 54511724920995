import {Component, computed, inject, OnInit} from '@angular/core';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {
  ChatSkeletonComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-container-pre-checking/chat-skeleton/chat-skeleton.component';

@Component({
  selector: 'app-chat-container-pre-checking',
  templateUrl: './chat-container-pre-checking.component.html',
  styleUrl: './chat-container-pre-checking.component.scss',
  imports: [
    ButtonComponent,
    SpinnerComponent,
    ChatSkeletonComponent,
    KeenIconComponent,
  ],
  standalone: true
})
export class ChatContainerPreCheckingComponent implements OnInit {
  // INJECTS
  chatWidgetService = inject(ChatWidgetService);

  // SIGNALS
  isError = computed(() => this.chatWidgetService.initTinodeChatServiceService.tinode.isFailedConnection());

  // METHODS
  retryToLogin() {
    this.chatWidgetService.resetSecretAndTopic();
  }

  ngOnInit() {
    this.chatWidgetService.initTinodeChatServiceService.tinode.failedConnectionStatus.subscribe(val => {
      if (val === 401 || val === 503) {
        setTimeout(() => {
          this.chatWidgetService.logOutFromChat();
        }, 100);
      }
    });
  }

}
