import { environment } from "environments/environment";

const KNOWN_HOSTS = {hosted: environment.chatWsUrl, local: environment.chatWsUrl};

const DEFAULT_HOST = KNOWN_HOSTS.hosted;

export const detectServerAddress = () => {
  let host = DEFAULT_HOST;
  if (typeof window.location === 'object') {
    if (window.location.protocol === 'file:' || window.location.hostname === 'localhost') {
      host = KNOWN_HOSTS.local;
    } else if (window.location.hostname) {
      host = KNOWN_HOSTS.hosted;
    }
  }

  return host;
};

export interface IChatConfig {
  APP_NAME: string;
  HOST: string;
  API_KEY: string;
  PLATFORM: 'web';
  SECURE: boolean;
  TRANSPORT: 'ws' | 'lp';
  AUTO_RECONNECT: boolean;
}

export const chatConfig: IChatConfig = {
  APP_NAME: 'TinodeWeb/' + '0.22.12',
  HOST: detectServerAddress(),
  API_KEY: 'AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K',
  SECURE: true,
  TRANSPORT: 'ws',
  AUTO_RECONNECT: false,
  PLATFORM: 'web'
};

