export class Drafty {
  static parse(a: any) {
  }

  static isPlainText(a: any): boolean {
    return false;
  }

  static getContentType() {
  }

  static hasAttachments(a?: any) {
    return false;
  }

  static attachments(a: any, b: any) {
  }
}
