import {environment} from '../../../../../environments/environment';

export const FILE_SERVICE_PATH = environment.apiUrl + '/chat-api/api/v1/user/';
export const CHAT_BOT_PATH = environment.apiUrl + '/chat-api/api/v1/bot/check/';
export const COATO_BRANCHES_REGIONS_PATH = environment.apiUrl + '/crm/api/v1/coato-branches/regions-dropdown/';
export const COATO_BRANCHES_PATH = environment.apiUrl + '/crm/api/v1/coato-branches/allowed-coato-list';

export const FILE_UPLOAD_FILE_SIZE_LIMIT = 8388608;

export const CUSTOMER_SECRET_KEY = 'CUSTOMER_SECRET_KEY';
export const CUSTOMER_CONNECTION_TOPIC_KEY = 'TOPIC';
export const CHAT_TYPE = 'CHAT_TYPE';
export const REQUEST_ID_CHAT_BOT = 'REQUEST_ID';

export const ACCOUNT_TYPE_LEGAL = 'legal';
export const ACCOUNT_TYPE_HOUSEHOLD = 'household';

export const CHAT_BOT = 'CHAT_BOT';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';

export const MESSAGE_TYPE_BALANCE = 'balance';
export const MESSAGE_TYPE_PROVIDE = 'provide';
export const MESSAGE_TYPE_CAS = 'cas';
export const MESSAGE_TYPE_TARIFF = 'tariff';
export const MESSAGE_TYPE_METER_SERIAL_NUMBER = 'info';

export const TINODE_PARAMS_DATA = 'data';
export const TINODE_PARAMS_SUB = 'sub';
export const TINODE_PARAMS_DESC = 'desc';
export const TINODE_PARAMS_DEL = 'del';

export const IS_FIRST_MESSAGE = 'IS_FIRST_MESSAGE';
export const OPERATOR_BOT_MESSAGE = 'OPERATOR_BOT_MESSAGE';
