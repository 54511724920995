<form [formGroup]="chatBotService.chatBotForm">
  <div class="grid-wrapper">
    @for (type of chatBotService.accountTypePanel; track type) {
      <label [for]="type.type" class="radio-card"
             [style.opacity]="isMessageTypeMeterSerialNumberSelected && type.type ===ACCOUNT_TYPE_LEGAL  ? '0.5': '1'"
             [style.pointer-events]="isMessageTypeMeterSerialNumberSelected && type.type ===ACCOUNT_TYPE_LEGAL ? 'none': 'all'"
      >
        <input name="account_type" class="disabled" type="radio" [id]="type.type" [value]="type.type"
               (change)="chatBotService.handleAccountTypeButton(type)"
               formControlName="account_type"/>
        <div class="card-content-wrapper">
          <div class="card-content mb-0">
            <keen-icon [name]="type.icon" type="duotone"/>
            <h4 class="mb-0">{{ type.title }}</h4>
          </div>
        </div>
      </label>
    }
  </div>
</form>
