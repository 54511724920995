import {Component, inject} from '@angular/core';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';

@Component({
  selector: 'app-chat-header',
  standalone: true,
  imports: [
    KeenIconComponent,
    SpinnerComponent
  ],
  templateUrl: './chat-header.component.html',
  styleUrl: './chat-header.component.scss'
})
export class ChatHeaderComponent {
  chatWidgetService = inject(ChatWidgetService);

  logOut() {
    this.chatWidgetService.logOutFromChat();
  }

}
