import {Subject} from 'rxjs';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {FILE_UPLOAD_FILE_SIZE_LIMIT} from '@chat-service/pages/customer-chat/customer-chat-config';
import {Component, computed, ElementRef, EventEmitter, inject, Input, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'chat-input',
  template: `
    <div class="chat-message-bottom" #bottom></div>

    <div class="d-flex"
         [class]="chatWidgetService.initTinodeChatServiceService.tinode ? isError() || chatWidgetService.isLoading() ? 'chat-input-disabled' : '': ''">
      <div class="w-[70%]">
                <textarea (input)="onInputChange()"
                          (focus)="chatWidgetService.initTinodeChatServiceService.tinode ?this.chatWidgetService.sendNote(): null"
                          class="chat-input-text w-[100%]"
                          placeholder="Xabarni kiriting..."
                          #message (keydown.enter)="onSubmit()" (keyup.enter)="message.value = ''"></textarea>
      </div>
      <div class="w-[30%]">
        <button type="submit" class="chat-input-submit" (click)="onSubmit()">
          <keen-icon name="send" type="duotone" class="chat-input-submit-icon"/>
        </button>
        @if (chatWidgetService.secretKey && chatWidgetService.topic) {
          <button type="submit" class="chat-input-submit" style="margin-right: 4px" (click)="openFile()">
            <keen-icon name="file-up" type="duotone" class="chat-input-submit-icon"/>
          </button>
        }
      </div>
    </div>
    <input type="file" [hidden]="true" (change)="onFileSelected($event)" #file/>
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./chat-input.component.css'],
  imports: [
    KeenIconComponent
  ]
})
export class ChatInputComponent {
  @ViewChild('message', {static: true}) message: ElementRef;
  @ViewChild('file', {static: true}) file: ElementRef;
  @ViewChild('bottom') bottom: ElementRef;
  @Input() public focus: Subject<unknown> = new EventEmitter();

  // INJECTS
  chatWidgetService = inject(ChatWidgetService);

  isError = computed(() => this.chatWidgetService.initTinodeChatServiceService.tinode.isFailedConnection());


  // METHODS
  onSubmit() {
    const message = this.getMessage();

    if (message.trim() === '') {
      return;
    }

    if (this.chatWidgetService.initTinodeChatServiceService.tinode) {
      this.chatWidgetService.sendMessage(message);
    } else {
      this.chatWidgetService.firstMessage = message;
    }
    this.chatInputResets();
  }

  openFile() {
    this.file.nativeElement.click();
  }


  onInputChange() {
    if (this.chatWidgetService.initTinodeChatServiceService.tinode) {
      this.chatWidgetService.sendTypingInfo();
    }

  }

  chatInputResets() {
    this.scrollToBottom();
    this.clearMessage();
    this.focusMessage();
  }

  scrollToBottom() {
    if (this.bottom !== undefined) {
      this.bottom.nativeElement.scrollIntoView();
    }
  }

  focusMessage() {
    this.message.nativeElement.focus();
  }

  onFileSelected(event: any) {
    if (event.target.files[0].size > FILE_UPLOAD_FILE_SIZE_LIMIT) {
      this.chatWidgetService.isFileSizeError.set(true);

      setTimeout(() => {
        this.restFileSizeError();
      }, 3000);
    } else {
      this.chatWidgetService.fileUpload(event.target.files[0]);
      this.resetFile();
    }
  }

  restFileSizeError() {
    this.chatWidgetService.isFileSizeError.set(false);
  }

  getMessage() {
    return this.message.nativeElement.value;
  }

  clearMessage() {
    this.message.nativeElement.value = '';
  }

  resetFile() {
    this.file.nativeElement.value = '';
  }
}
