import {Component} from '@angular/core';

@Component({
  selector: 'app-chat-skeleton',
  templateUrl: './chat-skeleton.component.html',
  styleUrl: './chat-skeleton.component.scss',
  standalone: true,
  imports: []
})
export class ChatSkeletonComponent {

}
