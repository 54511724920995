import {Component, inject, OnInit} from '@angular/core';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {ReactiveFormsModule} from '@angular/forms';
import {ACCOUNT_TYPE_LEGAL} from '@chat-service/pages/customer-chat/customer-chat-config';

@Component({
  selector: 'app-account-type-select',
  standalone: true,
  imports: [
    KeenIconComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './account-type-select.component.html',
  styleUrl: './account-type-select.component.scss'
})
export class AccountTypeSelectComponent implements OnInit {
  chatBotService = inject(ChatBotService);

  isMessageTypeMeterSerialNumberSelected: boolean;

  protected readonly ACCOUNT_TYPE_LEGAL = ACCOUNT_TYPE_LEGAL;


  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.isMessageTypeMeterSerialNumberSelected = this.chatBotService.checkIfMessageTypeMeterSerialNumberSelected();
  }
}
