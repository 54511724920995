import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumericOnly]',
  standalone: true
})

export class NumericOnlyDirective {
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
}
