<div class="grid-wrapper">
  @for (branch of chatBotService.coatoBranches(); track branch.code) {
    <button class="radio-card"
            (click)="chatBotService.handleGetCoatoRegionId(branch)">
      <div class="card-content-wrapper">
        <div class="card-content mb-0">
          <keen-icon name="geolocation" type="duotone"/>
          <h4 class="mb-0 card-content-text">{{ branch.name }}</h4>
        </div>
      </div>

    </button>
  }
</div>
