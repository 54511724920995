import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {NumericOnlyDirective} from '@chat-service/helpers/numeric-only.directive';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {ReactiveFormsModule} from '@angular/forms';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';

@Component({
  selector: 'app-account-number-input',
  standalone: true,
  imports: [
    NumericOnlyDirective,
    ReactiveFormsModule,
    KeenIconComponent,
    ButtonComponent
  ],
  templateUrl: './account-number-input.component.html',
  styleUrl: './account-number-input.component.scss'
})
export class AccountNumberInputComponent implements OnInit {
  @ViewChild('bottom') bottom: ElementRef;

  chatBotService = inject(ChatBotService);
  isMessageTypeMeterSerialNumberSelected: boolean;

  submit() {
    this.chatBotService.handleFormSubmit();
  }

  ngOnInit() {
    this.isMessageTypeMeterSerialNumberSelected = this.chatBotService.checkIfMessageTypeMeterSerialNumberSelected();
  }
}
