@if (isError()) {
  <div class="d-flex justify-content-center">
    <button
      (click)="retryToLogin()"
      class="
            btn
            btn-primary
            fw-bold
            border-0
            btn-outline
            align-items-center"
    >
      <div>
        @if (chatWidgetService.isLoading()) {
          <div
            class="inline-block h-4 w-4 animate-spin rounded-full border-3 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
            role="status">
          </div>
        } @else {
          <div class="d-flex align-items-center gap-2">
            <keen-icon name="arrows-circle"/>
            <span>
              {{ chatWidgetService.initTinodeChatServiceService.tinode.failedConnectionMessage() }}
              </span>
          </div>
        }
      </div>
    </button>

  </div>
} @else {
  @if (chatWidgetService.initTinodeChatServiceService.tinode.isConnecting()) {
    <app-chat-skeleton/>
  } @else {
    @if (chatWidgetService.isLoading()) {
      <div class="h-[300px]">
        <app-chat-skeleton/>
      </div>
    } @else {
      <ng-content/>
    }
  }
}
