import {Component, inject} from '@angular/core';
import {BasicForm} from '@shared/components/forms/basic/basic.form';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {InputComponent} from '@shared/components/forms/components/input/input.component';
import {ISocketResponse} from '@chat-service/pages/customer-chat/interfaces';

@Component({
  selector: 'app-chat-login',
  templateUrl: './chat-login.component.html',
  styleUrl: './chat-login.component.scss',
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
  ]
})
export class ChatLoginComponent extends BasicForm {
  _defaultService = inject(ChatWidgetService);

  form: FormGroup = this._fb.group({
    name: [null, Validators.required],
    phone: [null, Validators.required],
  });

  override afterSuccessSubmit(res: ISocketResponse) {
    this.isPending.set(false);
    this._defaultService.secretKey = res.data.secret;
    this._defaultService.topic = res.data.topic;
    this.form.reset();
  }
}
