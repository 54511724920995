export enum NegotiableCardBalanceTypes {
  // Электроэнергия
  ELECTRICITY = '1',

  // Акт нарушения
  ACT = '2',

  // Пеня
  FINE = '3',

  // Бюджет
  BALANCE = '4',

  // Акт списания
  WRITE_OFF_ACT = '5',

  // Акт безучетное потреб.
  ACT_UNACCOUNTED_CONSUMPTION = '6',

  // Услуги
  SERVICES = '7',
}
