import {Component} from '@angular/core';

@Component({
  selector: 'app-file-size-error',
  templateUrl: './file-size-error.component.html',
  styleUrl: './file-size-error.component.scss',
  standalone: true,
  imports: []
})
export class FileSizeErrorComponent {

}
