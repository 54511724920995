import {Component, inject} from '@angular/core';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {ReactiveFormsModule} from '@angular/forms';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';

@Component({
  selector: 'app-question-buttons',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    KeenIconComponent
  ],
  templateUrl: './question-buttons.component.html',
  styleUrl: './question-buttons.component.scss'
})
export class QuestionButtonsComponent {
  chatBotService = inject(ChatBotService);
}
