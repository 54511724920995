import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {IFileDownload} from '@chat-service/pages/customer-chat/interfaces';
import {
  ChatContainerPreCheckingComponent
} from '@chat-service/pages/customer-chat/chat-widget/chat-message/components/chat-container-pre-checking/chat-container-pre-checking.component';

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrl: './chat-container.component.scss',
  imports: [
    SpinnerComponent,
    ButtonComponent,
    ChatContainerPreCheckingComponent,
    KeenIconComponent,
  ],
  standalone: true
})
export class ChatContainerComponent implements OnInit, OnDestroy {
  // INJECTS
  chatWidgetService = inject(ChatWidgetService);

  uniqFileRef: string;

  // LIFE_CYCLES
  ngOnInit() {
    if (this.chatWidgetService.secretKey && this.chatWidgetService.topic) {
      this.chatWidgetService.startChat();
    }
  }

  ngOnDestroy() {
    this.chatWidgetService.resetMessagesData();
  }

  download(file: IFileDownload) {
    this.uniqFileRef = file.ref;
    this.chatWidgetService.fileDownload(file);
  }
}
