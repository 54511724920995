<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="modal-header">
    Chatda o‘zingizni tanishtiring
  </div>
  <div class="modal-body">
    <div class="mb-1">
      <ups-input
        formControlName="name"
        label=""
        placeholder="Ismingiz*"
      />
    </div>
    <div class="mb-1">
      <ups-input
        [dropSpecialCharacters]="true"
        [showMaskTyped]="true"
        [specialCharacters]="['+', '-', '(', ')']"
        formControlName="phone"
        mask="+000(00)-000-00-00"
        placeholder="Telefon raqamingiz*"
      />
    </div>
    <ups-button
      [disabled]="!form.valid || isPending()"
      [isPending]="isPending()"
      class="w-100"
      label="Operatorga bo'g'lanish"
      styleType="primary"
      type="submit"
    />
  </div>
</form>
