import {Component, inject, OnInit} from '@angular/core';
import {ChatBotService} from '@chat-service/pages/customer-chat/chat-widget/chat-bot/chat-bot.service';
import {JsonPipe} from '@angular/common';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-coato-branches-regions',
  standalone: true,
  imports: [
    JsonPipe,
    KeenIconComponent,
    ReactiveFormsModule
  ],
  templateUrl: './coato-branches-regions.component.html',
  styleUrl: './coato-branches-regions.component.scss'
})
export class CoatoBranchesRegionsComponent implements OnInit {
  chatBotService = inject(ChatBotService);

  ngOnInit() {
    this.chatBotService.getCoatoBranchesRegion();
  }
}
