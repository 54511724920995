import {Injectable} from '@angular/core';
import {Tinode} from '@chat-service/pages/customer-chat/tinode-sdk/typescript-sdk-master/src';
import {chatConfig} from '@chat-service/pages/customer-chat/tinode-sdk/chat-config';

@Injectable({
  providedIn: 'root'
})
export class InitTinodeChatServiceService {

  public tinode: Tinode;

  initTinodeChat() {
    this.tinode = new Tinode(chatConfig.APP_NAME, chatConfig.PLATFORM, {
      host: chatConfig.HOST,
      APIKey: chatConfig.API_KEY,
      secure: chatConfig.SECURE,
      transport: chatConfig.TRANSPORT,
      autoReconnect: chatConfig.AUTO_RECONNECT
    });
  }
}
