import {Component, inject} from '@angular/core';
import {ChatWidgetService} from '@chat-service/pages/customer-chat/services/chat-widget.service';

@Component({
  selector: 'app-file-uploading-info',
  templateUrl: './file-uploading-info.component.html',
  styleUrl: './file-uploading-info.component.scss',
  standalone: true,
  imports: []
})
export class FileUploadingInfoComponent {
  // INJECTS
  chatWidgetService = inject(ChatWidgetService);
}
