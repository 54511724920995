import {Component} from '@angular/core';

@Component({
  selector: 'app-typing-indicator-dots',
  standalone: true,
  imports: [],
  templateUrl: './typing-indicator-dots.component.html',
  styleUrl: './typing-indicator-dots.component.scss'
})
export class TypingIndicatorDotsComponent {

}
